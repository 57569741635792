import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { OnScrollPopup } from "../hr-chatbot";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title=" Chatbots vs. Conversational AI - Major Differences – Workativ Assistant "
        description="Explore chatbots vs. conversational AI, learn how conversational AI differentiate from chatbots. Workativ provides easy platform to quickly build your Conversational AI and automate workplace support"
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={topImage}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header">
                            Chatbots vs Conversational AI
                          </h1>
                        </div>
                      </div>
                      <section className="table_contents_wrapper">
                        <h3 class="font-section-sub-header-small">
                          Table of contents
                        </h3>
                        <div className="table_contents_link font-section-normal-text-medium">
                          <AnchorLink offset={180} href="#section1">
                            1. What are chatbots?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section2">
                            2. What is a conversational AI?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section3">
                            3. What separates conversational AI from chatbots?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section4">
                            4. Reasons to choose chatbots
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section5">
                            5. Reasons to choose conversational AI
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section6">
                            6. Use Cases: chatbots & conversational AI
                          </AnchorLink>
                        </div>
                      </section>

                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 market_wrapper_page line-height-2">
                        <p class="font-section-normal-text line-height-2">
                          If you have used the terms Chatbots and Conversational
                          AI interchangeably, then you are wrong. But fret not,
                          you are in the majority as most people make this
                          mistake.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          {" "}
                          Let us look at each of these technologies
                          individually.{" "}
                        </p>

                        {/* Section 1 */}
                        <div id="section1">
                          <h2 className="font-section-sub-header-small-bold">
                            What are chatbots?
                          </h2>
                          <p class="font-section-normal-text line-height-2">
                            Chatbots use rule-based programming to match queries
                            with potential answers. They are limited in their
                            capacity. If they receive a request that hasn’t been
                            defined previously, it will revert with a “I did not
                            understand.” In other words, it isn’t smart, per se.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            The global chatbot market is{" "}
                            <a href="https://research.aimultiple.com/chatbot-stats/">
                              expected to reach $1.3 billion by 2025,
                            </a>{" "}
                            and the interest in it has increased 5X in the last
                            10 years.{" "}
                            <a href="https://www2.deloitte.com/content/dam/Deloitte/nl/Documents/financial-services/deloitte-nl-fsi-chatbots-adopting-the-power-of-conversational-ux.pdf">
                              According to Deloiette,
                            </a>{" "}
                            chatbots are projected to save almost $8 billion for
                            businesses by 2022.
                          </p>
                        </div>
                      </div>
                      {/* Section 2 */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 market_wrapper_page line-height-2">
                        <div id="section2">
                          <h3 className="font-section-sub-header-small-bold">
                            What is a conversational AI?
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            It is an advanced communication software which
                            learns with time to improve interactions. As the
                            name suggests, it uses a myriad of artificial
                            intelligence technologies to converse
                            ‘intelligently’. It is expected that{" "}
                            <a href="https://www.financedigest.com/ai-will-power-95-of-customer-interactions-by-2025.html">
                              {" "}
                              more than 95% of customer interactions{" "}
                            </a>
                            will be taken over by AI by 2025.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            The difference between chatbots and conversational
                            AI chatbot is that the latter provides far more
                            flexibility, personalization options, helps build
                            and automate simple complex conversations and use
                            cases.
                          </p>
                        </div>
                      </div>
                      {/* Section 3 */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 market_wrapper_page line-height-2">
                        <div id="section3">
                          <h3 className="font-section-sub-header-small-bold">
                            What separates conversational AI from chatbots?
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            The technologies and the use cases of both chatbots
                            and conversational AI chatbots are world’s apart,
                            although there are certain common aspects to them.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            Before that, here’s an overview of chatbots and
                            conversational AI.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            Chatbots are of two types: supervised and
                            unsupervised. Supervised chatbots follow a
                            predefined set of conversations or script while
                            unsupervised chatbots is a machine learning-driven
                            chatbot that requires tonnes of data to train itself
                            to get better.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            Conversational AI automates highly-personalized
                            resolutions at scale by understanding intent,
                            decoding language and context to offer human-like
                            responses.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            Let us look at the main differences which govern
                            these technologies.
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page">
                        <h3 className="font-section-sub-header-small">
                          #1 Conversational AI understands:
                        </h3>

                        <p class="font-section-normal-text line-height-2">
                          It understands complex sentences the same way humans
                          do. Conversations between real humans are not always
                          straightforward, and it uses a variety of elements
                          such as slang, jargons, even mispronounced words. The
                          conversational AI chatbot can even comprehend these
                          types of interactions.
                        </p>

                        <h3 className="font-section-sub-header-small">
                          #2 Supports transactions:
                        </h3>
                        <p class="font-section-normal-text line-height-2">
                          Conversational AI chatbot goes beyond mere rule-based
                          tasks, and can complete complex transactions that will
                          help even enterprises. From processing healthcare
                          claims to loan processing, they can act as a major
                          fillip to your business.
                        </p>

                        <h3 className="font-section-sub-header-small">
                          #3 Available on voice, text and web:
                        </h3>
                        <p class="font-section-normal-text line-height-2">
                          They are available across a number of channels and can
                          be easily accessed without any hiccups. From being
                          available on an enterprise portal to a voice-based
                          system like Siri or Alexa, it accommodates omnichannel
                          interactions.
                        </p>

                        <h3 className="font-section-sub-header-small">
                          #4 Uses previous interactions:
                        </h3>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-enterprise">
                            {" "}
                            Conversational AI integrates with your enterprise’s
                            existing system
                          </a>{" "}
                          to go through the previous interactions to understand
                          how everything works. It uses the previous history to
                          improve the future interactions to troubleshoot issues
                          or improve processes.
                        </p>
                      </div>
                      <NocodeWrapper />

                      {/* <OnScrollPopup /> */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 market_wrapper_page">
                        <h2 className="font-section-sub-header-small-bold">
                          Chatbots vs Conversational AI: Which one to choose?
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          Let us look at why businesses should choose these
                          technologies and how it can help them.
                        </p>

                        {/* Section 4 */}
                        <div id="section4">
                          <h3 className="font-section-sub-header-small-bold color_black_span">
                            Reasons to choose Chatbots:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Chatbots are conversational robots which mostly
                            appear as a pop-up in the bottom right of your
                            screen when you are on a website. Let us look at
                            what it offers to your customers, employees,
                            executives, and businesses.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            1. Generates more conversations:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Millennials prefer interacting with chatbots over a
                            customer support executive,{" "}
                            <a href="https://chatbotslife.com/chatbots-and-customer-engagement-why-millennials-know-best-8d530cce831c">
                              says a survey conducted by MobileMarketer.
                            </a>{" "}
                            Why? Because chatbots’ responses are precise and
                            don't take time. Customers also believe that they
                            can ask any question without being judged for it.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            When you implement chatbots into your business, you
                            can expect a lot more conversations to happen with
                            customers. You can use this opportunity to bring
                            them deeper into your sales funnel.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            The banking, retail and healthcare sectors will{" "}
                            <a href="https://www.businessinsider.com/chatbot-market-stats-trends?IR=T">
                              save more than $11 billion a year{" "}
                            </a>
                            by using chatbots in customer service.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            2. Handles multiple customers:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Unlike humans, it is easy for chatbots to handle
                            multiple customers at the same time. By answering
                            multiple queries at the same time, thanks to
                            automation, chatbots save your employees’ time. It
                            lets the employees concentrate on higher value
                            tasks.{" "}
                            <a href="https://www.juniperresearch.com/press/chatbots-to-deliver-11bn-cost-savings-2023">
                              5 billion hours
                            </a>{" "}
                            are expected to be saved because of chatbots, by
                            2023.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            3. They can analyse data:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Once you have a chatbot to answer business queries,
                            you can even create reports at the end of a month or
                            a specific period. Using this report, you can see
                            which are the most popular questions, how customers
                            are using them and so on. These insights can help
                            you create better business strategies.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            4. Speak multiple languages:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Imagine you are catering to the European community,
                            you will require more than just English speaking
                            agents. Hiring multiple customer support executives
                            for chats can be expensive. Thankfully, chatbots can
                            handle multiple languages. All it requires is that
                            they be programmed to speak the languages of your
                            choice.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            5. Easy deployment:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            If you are of the opinion that company-specific
                            chatbot can be deployed only on your website, you
                            couldn't be more wrong. Create your chatbot and
                            deploy it on as many channels as you want- Facebook,
                            Slack, Whatsapp, you name it.
                          </p>
                        </div>
                      </div>
                      {/* Section 5 */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 market_wrapper_page">
                        <div id="section5">
                          <h3 className="font-section-sub-header-small-bold">
                            Reasons to choose Conversational AI:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Before we delve into the reasons to choose
                            conversational AI, you need to know that the
                            features of chatbot such as the ability to generate
                            more conversations, handle multiple customers,
                            answer in multiple languages and data analysis
                            capability is something that conversational AI can
                            do as well.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            <a href="https://www.technologyreview.com/2018/11/14/239924/humans-bots-tension-and-opportunity/">
                              An MIT Technology Review report says{" "}
                            </a>
                            that more than 90% reported that they have seen
                            significant improvements in complaint resolution,
                            call processing, customer and employee satisfaction
                            after using conversational AI. Let us look at some
                            of the reasons why it makes sense to use
                            conversational AI for your business.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            1. Adds a human touch:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            One of the biggest advantages of conversational AI
                            chatbot is that it mimics human interaction. If you
                            want your customers to feel more welcome,
                            conversational tones are something that they will
                            appreciate you for, and conversational AI chatbot
                            does that with elan.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            2. Omnichannel:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            It provides omnichannel conversation as they are
                            available on different platforms and can even sync
                            the conversations across each of these platforms.
                            The quality of conversations across all the
                            platforms are the same, whether it be Whatsapp audio
                            or Facebook Messenger. It lets you offer
                            personalized and complete service for every
                            interaction while staying true to your brand voice
                            and tone.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            3. Leverages NLP & NLU:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Conversational AI chatbot leverages Natural Language
                            Processing and Natural Language Understanding (NLU)
                            to create advanced dialog systems which use memory,
                            preferences, and context to deliver an intelligent
                            natural language interface. It uses tonnes of data,
                            machine learning and the NLP/NLU technologies to
                            recognize speech and text inputs and translate their
                            meanings in different languages.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            4. Greater personalization:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            Personalization is one of the most powerful benefits
                            of conversational AI. When you know your customer
                            persona, have details about their likes and
                            preferences, and have a layer of intelligence such
                            as conversational AI, you will be able to recommend
                            the right products and services to them. With better
                            personalization, the chances of converting visitors
                            into customers will be high.
                          </p>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page">
                        <div id="section6">
                          <h3 className="font-section-sub-header-small-bold">
                            Use Cases: Chatbots & Conversational AI
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            We are going to take use cases from four specific
                            industries: Healthcare, Banking, Retail and HR to
                            show how chatbots and conversational AI chatbot can
                            help them. You will notice the difference in the use
                            cases of chatbots vis-a-vis conversational AI.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            Finance and Banking:{" "}
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Chatbots:&ensp;
                            </span>
                            From reducing the line in queues to reducing bank’s
                            expenses, chatbots can do a lot. It can answer basic
                            questions from customers, which can reduce the
                            workload of your employees. Information about the
                            different types of loans available at the bank to
                            queries on online services can be accessed with
                            ease. You can register a fraudulent transaction on
                            your account as soon as you notice it, instead of
                            having to go through the IVR system to reach an
                            agent.{" "}
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Conversational AI:&ensp;
                            </span>
                            Providing digitalized customer experiences for
                            customers is a game-changer in the banking and
                            finance sector. These AI bots can check user
                            balances and process transactions across all bank
                            accounts. The bots detect phrases or keywords that
                            could indicate fraudulent activity. It prevents
                            fraud using speech recognition and by looking for
                            anomalies in normal behaviour.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            Retail:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Chatbots:&ensp;
                            </span>
                            With chatbots, customers can search for products
                            based on a customer’s preference such as rating,
                            price range, features, discounts, and so on. You can
                            even order via chatbot, locate the nearest stores,
                            track packages, and send return information.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Conversational AI:&ensp;
                            </span>
                            It can make product recommendations based on user
                            interactions. Conversational AI chatbot can analyze
                            the search queries to derive insights into how the
                            business is operating. By pulling old conversations,
                            it can offer better and insightful customer service.
                            You can even track inventory using conversational
                            AI.{" "}
                            <a href="https://research.aimultiple.com/chatbot-stats/">
                              Over 70% of chatbot conversations
                            </a>{" "}
                            are expected to be with retail conversational AI
                            systems by 2023.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            HR:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Chatbots:&ensp;
                            </span>
                            For HR employees, chatbots help them complete their
                            daily tasks with speed and ease. For applicants,
                            chatbots provide a better{" "}
                            <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-focus-on-user-experience">
                              user experience by simplifying information
                            </a>{" "}
                            gathering and associated processes.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            Chatbots can be used to automate repetitive
                            enquiries. It can collect important applicant data
                            and integrate it with your existing systems. You can
                            create an internal chatbot to provide a great
                            onboarding experience as it is the same set of
                            processes and policies that you need to educate each
                            new recruit.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Conversational AI:&ensp;
                            </span>
                            Conversational AI chatbot provides instant feedback
                            exchange and performance insights which makes
                            superior performance management possible. Employee
                            training using conversational AI chatbot facilitates
                            greater interaction with the employees. It can
                            easily converge siloed systems so that there is easy
                            information access.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            IT Helpdesk:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Chatbots:&ensp;
                            </span>
                            For the IT Helpdesk, chatbots categorize the ticket
                            and send it to the right agent. It helps with
                            password resets, automates access provisioning and
                            de-provisioning, automates workflow, and so on.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Conversational AI:&ensp;
                            </span>
                            It has the potential to analyze service requests and
                            generate reports, offers personalized assistance to
                            customers, enhances engagement and experience,
                            deflects complex queries to an agent, and so on.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            Sales:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Chatbots:&ensp;
                            </span>
                            It analyzes user behaviour on the site and provides
                            personalized recommendations. The chatbot can be
                            programmed to subscribe visitors to newsletters,
                            events, webinars, etc. They even have the capacity
                            to connect visitors to salespeople automatically and
                            even help book demo appointments.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Conversational AI:&ensp;
                            </span>
                            Since conversational AI is great at parsing large
                            data sets, the sales team can use it to automate
                            cold outreach and lead generation. Thanks to its
                            natural speech abilities, it can nurture leads and
                            guide them through the sales funnel until they can
                            be converted. It can even send rewards, discount
                            offers and other types of incentives when the AI
                            realizes that the customer is losing interest.
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            Customer Support:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Chatbots:&ensp;
                            </span>
                            Customers can get instant support from chatbots
                            instead of having to wait for an agent. There are
                            some questions that are often asked by customers,
                            and chatbots are great for this purpose. It can help
                            with navigating specific pages, discover certain
                            information or receive instructions about tasks. It
                            can also help customers make payments, and can act
                            as a conversationalist by entertaining the customers
                            on topics such as weather, cinema, etc.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            <span className="font-section-normal-text-medium">
                              Conversational AI:&ensp;
                            </span>
                            It can efficiently understand customer’s
                            requirements and nudge visitors to go further into
                            the buyer’s journey. An intent-based recommendation
                            engine helps match user requirements with your
                            products, descriptions, customer reviews, and use
                            past data to recommend more accurately, thereby
                            increasing the conversions. Uses previous data to
                            predict a customer’s future set of actions. Allows
                            customers to get support just by using voice
                            commands.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            There are many more areas and use cases in different
                            verticals for chatbots and conversational AI. IoT
                            devices, public sector, construction, education, and
                            many more, which are being revolutionized by these
                            technologies.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            If you look at the use cases of chatbots and
                            conversational AI, there is a small but significant
                            difference. Every conversational AI-based use case
                            looks as if it has a layer of intelligence which
                            makes it possible to do complex tasks such as the
                            following- salary enquiries, processing leave
                            applications, expense claims, policy education,
                            employee onboarding, employee offboarding, and more.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            For example, in the banking use cases that we saw,
                            chatbots let customers register frauds with ease,
                            while conversational AI chatbots can even help banks
                            detect frauds. Do you see the stark difference
                            between the offerings of both these new-age
                            technologies?
                          </p>

                          <h3 className="font-section-sub-header-small ">
                            Conclusion:
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            While it might look as if these two technologies are
                            sparring with each other, that is certainly not the
                            case. Conversational AI chatbot is immensely
                            advanced and its use cases will only increase with
                            time, but with chatbots, there are limitations to
                            what it can do. The demand for both these
                            technologies will always be there, albeit for
                            different reasons. Conversational AI chatbot is
                            going to be a trusted form of communication and it
                            will be a far cry from the antiquated chatbots that
                            we have been witness to.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            If you are looking to empower your business with an
                            intelligent automation platform, try out Workativ’s
                            Conversational AI Chatbot{" "}
                            <a href="https://workativ.com/conversational-ai-platform">
                              here.{" "}
                            </a>
                          </p>
                        </div>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                                What Is Conversational AI? A Guide to
                                Conversational AI Chatbots
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                                {" "}
                                Slack Chatbot Guide for IT Helpdesk Automation
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-workplace-support-bot">
                                MS Teams Bot - Your ultimate workplace support
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper margin-no-code">
      <h4>No Code - Free Conversational AI</h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="virtual agent"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
